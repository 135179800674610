<template>
  <div :class="keyboardClass"></div>
</template>

<script>
import Keyboard from 'simple-keyboard';
import 'simple-keyboard/build/css/index.css';

export default {
  name: 'SimpleKeyboard',
  props: {
    keyboardClass: {
      default: 'simple-keyboard',
      type: String
    },
    input: {
      type: String
    }
  },
  data: () => ({
    keyboard: null
  }),
  mounted () {
    this.keyboard = new Keyboard({
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
      layout: {
        default: [ '{numpad1} {numpad2} {numpad3}', '{numpad4} {numpad5} {numpad6}', '{numpad7} {numpad8} {numpad9}', '{backspace} 0' ]
      }
    });
  },
  methods: {
    onChange (input) {
      this.$emit('onChange', input);
    }
  }
};
</script>
