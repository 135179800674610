<template>
  <div class="auth donor">
    <div class="donor-form-block">
      <img class="logo" src="/images/logo.svg">
      <div class="auth-title text-center">
        <strong>請輸入密碼登錄</strong>
        <span>Sign in with your password</span>
      </div>
      <div class="input-wrap">
        <v-text-field
          :id="'phone' + Math.random() * 1000"
          @focusin="showKeyboard = true"
          v-model="phone"
          type="password"
          placeholder="你的密碼 Your password"
          required
          dense
          readonly
          solo
          height="60"
          :hide-details="true"
        />
      </div>
      <div class="text">
        <strong>密碼為店舖電話號碼</strong>
        <span>The password is the branch phone number<br>(eg. 5340 1288)</span>
      </div>
      <v-btn
        color="primary"
        large
        max-width="100%"
        width="276"
        rounded
        class="mb-6"
        @click="authenticate"
      >
        登入 SIGN IN
      </v-btn>
      <p class="invalid primary--text text-center" v-if="error">
        Sorry, we don’t have your password on record.
        <br>Please try again or contact
        <span class="underline">your IT department</span>
        <br>密碼錯誤 ， 請重新輸入<br>如忘記密碼 ，請聯繫你的資訊科技部
      </p>
    </div>
    <simple-keyboard @onChange="onChange" v-if="showKeyboard"/>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import SimpleKeyboard from '@/components/simple-keyboard';

export default {
  name: 'Auth',
  metaInfo: {
    title: 'Auth'
  },
  data () {
    return {
      phone: null,
      showKeyboard: null,
      error: false
    };
  },
  methods: {
    ...mapActions(['donorLogin']),
    authenticate () {
      this.error = false;
      this.donorLogin(this.phone)
        .then(() => {
          this.$router.push('/donors/welcome');
        }, () => {
          this.error = true;
        });
    },
    onChange (input) {
      this.phone = input;
    }
  },
  components: { SimpleKeyboard }
};
</script>
